<!-- 添加标点 -->
<template>
  <div class="content">
    <div class="wrapperBox">
      <div id="wrapper"></div>
    </div>
  </div>
</template>

<script>
// let mapObj = null; // 生成组件实例
//
// window.mapInit = function() { // 挂载地图实例
//   mapObj = new window.TMap.Map("wrapper", {
//     center: new window.TMap.LatLng(22.254515, 113.469689), // 地图初始坐标
//     zoom: 14, // 缩放等级
//     mapStyleId: 'style1', // 地图样式
//     zoomControl: false, // 设置是否启用缩放控件
//   });
//
//   mapObj.on("click", function(evt) { // 地图全局事件
//     // 这里是通过获取地图点击的位置，将该位置移动至中心点，后面会用上
//     mapObj.setCenter(new window.TMap.LatLng(evt.latLng.getLat().toFixed(6), evt.latLng.getLng().toFixed(6)))
//   })
// }
export default {
  data() {
    return {
      mapObj: '',
      // 创建多个标点
      agriculture: [], // 生成图标实例1
      property: [], // 生成图标实例2
      construction: [], // 生成图标实例3
      noRented: [], // 生成图标实例4
      marker: [], // 生成标点实例
      geometries: [],
      key: 'NAXBZ-I3DW2-JYWUA-C4CTO-SD3HV-XIF3X',
    };
  },
  onLoad() {
    // #ifdef H5
    this.loadScrpit();
    // #endif
  },
  activated() {
    this.initMap();
  },
  methods: {
    initMap() {
      this.mapObj = new window.TMap.Map('wrapper', {
        center: new window.TMap.LatLng(22.254515, 113.469689), // 地图初始坐标
        zoom: 14, // 缩放等级
        mapStyleId: 'style1', // 地图样式
        zoomControl: false, // 设置是否启用缩放控件
      });

      // this.mapObj.on('click', (evt) => {
      //   // 地图全局事件
      //   // 这里是通过获取地图点击的位置，将该位置移动至中心点，后面会用上
      //   this.mapObj.setCenter(new window.TMap.LatLng(evt.latLng.getLat().toFixed(6), evt.latLng.getLng().toFixed(6)))
      // });
      this.marker = new window.TMap.MultiMarker({
        map: this.mapObj,
        geometries: [
          {
            position: new window.TMap.LatLng(22.254515, 113.469689),
          },
        ],
      });
      this.setMapMarker();
    },
    // 初始化
    loadScrpit() {
      // 挂载js
      var script = document.createElement('script');
      script.src = `https://map.qq.com/api/gljs?v=1.exp&key=${this.key}&libraries=visualization&callback=mapInit`;
      document.body.appendChild(script);
    },
    setMapMarker() {
      // 创建标点实例
      this.mapObj.on('click', (e) => {
        this.mapObj.setCenter(
          new window.TMap.LatLng(
            e.latLng.getLat().toFixed(6),
            e.latLng.getLng().toFixed(6),
          ),
        );
        this.geometries = [
          {
            position: new window.TMap.LatLng(
              e.latLng.getLat().toFixed(6),
              e.latLng.getLng().toFixed(6),
            ),
          },
        ];
        this.marker.setGeometries(this.geometries);
        this.getCode(this.marker.getGeometries()[0].position);
        // this.marker.updateGeometries(this.geometries);
      });

      // this.marker = new window.TMap.MultiMarker({
      //   // 构造函数创建标点实例
      //   id: 'marker-layer', // 定义的ID名称
      //   map: this.mapObj, // 挂载的地图实例
      //   geometries: geometries // 点标记数据数组
      // });
    },
    getCode(data) {
      this.$jsonp(`https://apis.map.qq.com/ws/geocoder/v1/`, {
        location: data.getLat() + ',' + data.getLng(),
        key: 'NAXBZ-I3DW2-JYWUA-C4CTO-SD3HV-XIF3X',
        output: 'jsonp',
      }).then((res) => {
        if (res.status === 0) {
          //
          // console.log(res.result.address_reference.landmark_l2.title)
          // console.log(res.result.address)
          // console.log(res)
          let area;
          if (res.result.address_reference.landmark_l2.title) {
            // console.log("1")
            area = res.result.address_reference.landmark_l2.title;
            // console.log(res.result.address_reference.landmark_l2.title);
          } else if (res.result.address_reference.street_number) {
            // console.log("2")
            area = res.result.address_reference.street_number.street;
            // console.log(res.result.address_reference.street_number.street);
          } else {
            // console.log("3")
            area = res.result.address_reference.town.title;
            // console.log(res.result.address_reference.town.title);
          }
          // console.log(res.result)
          console.log(res.result.address + area);
          // console.log(res.result.address_component)
          // console.log(res.result.ad_info.adcode)
        }
      });
    },
  },
};
</script>

<style>
.content {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wrapperBox {
  position: relative;
  width: 100vw;
  height: 100vh;
  z-index: 1;
}

#wrapper {
  z-index: 20;
  width: 120vw;
  height: 120vh;
  top: -10vh;
  left: -10vw;
  position: absolute;
}
</style>
